import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../wrappers/Layout';
import Image from '../components/Image';
import RichText from '../components/RichText';
import Accordian from '../components/Accordian';

const StoreCard = ({ image, info }) => {

	return (
		<div className="store-card">
			<Image image={image.large} />
			<div className="p2 store-card-copy padx2">
				<RichText content={info} />
			</div>
		</div>
	)
}

const StoresAndStockists = ({ data, location }) => {

	const { titleStores, stores, titleStockists, stockists } = data.allContentfulLocationsPage.nodes[0];

	return (
		<Layout title={`Stores & Stockists | Harrys London`} location={location}>
			<h1 className="page-gutters outerx4 align-center">{titleStores}</h1>
			<div className="outerx4 grid gapsx4 page-gutters stores-grid">
				{stores.map((store, index) => <StoreCard {...store} key={index} />)}
			</div>
			<div className="innerx2">
				<h1 className="page-gutters outerx4 align-center">{titleStockists}</h1>
				{stockists.map((stockist, index) => <Accordian name={stockist.name} copy={stockist.copy} key={index} />)}
			</div>
		</Layout>
	);
}

export default StoresAndStockists;

export const StoresStockistsQuery = graphql`
	query {
		allContentfulLocationsPage(limit: 1) {
			nodes {
				titleStores
				stores {
					title
					image {
						large: gatsbyImageData(
							layout: FULL_WIDTH
						)
					}
					info {
						raw
					}
				}
				titleStockists
				stockists {
					name
					copy: stockistsCopy {
						raw
					}
				}
			}
		}
	}
`