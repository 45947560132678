import React, { useState } from 'react';
import RichText from '../components/RichText';

// ------------
// TODO: Rename this and make className more generic and reusable

const Accordian = ({ name, copy }) => {

	const [open, setOpen] = useState(false);

	const toggleOpen = () => setOpen(o => !o);

	return (
		<div className="stockist-region bdr-t">
			<button className="innerx4 align-center w100" onClick={toggleOpen}>
				<h1>{name}</h1>
			</button>
			{open && (
				<div className="align-center mbx4 max-s mauto">
					<RichText content={copy} />
				</div>
			)}
		</div>
	)
}

export default Accordian